import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';
export const getAllOrgAdminsByOrgIdAPI = async (obj: any) => {
    return await apiGet(
        ENDPOINT_DEV +
            `organization/${obj.orgId}/org-admin` +
            (obj.limit ? '?limit=' + obj.limit : '') +
            (obj.isOrgAdminPrivilege ? '?isOrgAdminPrivilege=' + obj.isOrgAdminPrivilege : ''),
    );
};
